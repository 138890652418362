import styles from "./PagePreloader.module.css";

const PagePreloader = () => {
    return (
        <div className={styles.preloader}>
            <div className={styles.wrapper}>
            <div className={styles.title}>
                <img src="/logo-xerone-750.png" width={300} />
            </div>
            <div className={styles.boxes}>
                <div className={styles.box}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={styles.box}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={styles.box}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={styles.box}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default PagePreloader;
