import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/** emotion */
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

/** MUI */
import { StylesProvider, createGenerateClassName } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

/** NextJS */
import Head from "next/head";
import Router from "next/router";

/** UI */
import PagePreloader from "../src/pages/components/PagePreloader";
import theme from "../src/theme";

export const cache = createCache({ key: "xerone", prepend: false });

const crypto = require("crypto"),
    shasum = crypto.createHash("sha1");
shasum.update("Xerone");

const generateClassName = createGenerateClassName({
    productionPrefix: "x1--" + shasum.digest("hex").substr(0, 13),
    disableGlobal: true,
});

const MainApp = (props) => {
    const { Component, pageProps } = props;

    const [pageLoading, setPageLoading] = useState(true);

    /** Effects */
    useEffect(() => {
        const start = () => setPageLoading(true);
        const end = () =>
            setTimeout(() => {
                setPageLoading(false);
            }, 500);

        Router.events.on("routeChangeStart", start);
        Router.events.on("routeChangeComplete", end);
        Router.events.on("routeChangeError", end);
        end();
        return () => {
            Router.events.off("routeChangeStart", start);
            Router.events.off("routeChangeComplete", end);
            Router.events.off("routeChangeError", end);
            end();
        };
    }, []);

    useEffect(() => {
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    return (
        <React.Fragment>
            <CacheProvider value={cache}>
                <StylesProvider generateClassName={generateClassName}>
                    <Head>
                        <meta name="viewport" content="initial-scale=1, width=device-width" />
                        <title>{`Xerone solutions`}</title>

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta property="og:url" content="https://xerone.produos.dev/" />
                        <meta property="og:title" content="Xerone solutions" />
                        <meta property="og:description" content="The Gold Standard in Content Validation" />
                        <meta property="og:image" content="https://xerone.produos.dev/xerone-og.jpg" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta property="twitter:url" content="https://xerone.produos.dev/" />
                        <meta name="twitter:title" content="Xerone solutions" />
                        <meta name="twitter:description" content="The Gold Standard in Content Validation" />
                        <meta name="twitter:image" content="https://xerone.produos.dev/xerone-og.jpg" />
                    </Head>

                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            {/* {pageLoading ? (
                                <PagePreloader />
                            ) : (
                                <>
                                    
                                    <Component {...pageProps} />
                                </>
                            )} */}
                            {pageLoading && <PagePreloader />}

                            {/* <CssBaseline /> */}
                            <Component {...pageProps} />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </StylesProvider>
            </CacheProvider>
        </React.Fragment>
    );
};

export default MainApp;

MainApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
};
