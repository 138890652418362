import { createTheme } from "@mui/material/styles";
import { lightBlue, orange } from "@mui/material/colors";
import { alpha } from "@mui/system";
// console.log({deepPurple, teal})

const primaryColors = lightBlue;
const secondaryColors = orange;

const defaultTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#1596f3", // primaryColors["500"],
            colors: primaryColors,
        },
        secondary: {
            main: secondaryColors["500"],
            colors: secondaryColors,
        },
    },
    typography: {
        htmlFontSize: 16,
        fontFamily: "'Exo', sans-serif",
        fontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontWeight: 300,
            fontSize: "6rem",
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
        },
        h2: {
            fontWeight: 300,
            fontSize: "3.75rem",
            lineHeight: 1.2,
            letterSpacing: "-0.00833em",
        },
        h3: {
            fontWeight: 300,
            fontSize: "3rem",
            lineHeight: 1.167,
            letterSpacing: "0em",
        },
        h4: {
            fontWeight: 300,
            fontSize: "2.125rem",
            lineHeight: 1.235,
            letterSpacing: "0.00735em",
        },
        h5: {
            fontWeight: 300,
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
        },
        h6: {
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: 1.6,
            letterSpacing: "0.0075em",
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.75,
            letterSpacing: "0.00938em",
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.57,
            letterSpacing: "0.00714em",
        },
        body1: {
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
        },
        body2: {
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: 1.43,
            letterSpacing: "0.01071em",
        },
        button: {
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.75,
            letterSpacing: "0.02857em",
            textTransform: "uppercase",
        },
        caption: {
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            letterSpacing: "0.03333em",
        },
        overline: {
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 2.66,
            letterSpacing: "0.08333em",
            textTransform: "uppercase",
        },
    },
});

// console.log(defaultTheme.palette)

const { breakpoints } = defaultTheme;

const theme = {
    ...defaultTheme,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontSize: 16,
                    [breakpoints.down("sm")]: {
                        fontSize: "0.875rem",
                    },
                    [breakpoints.down("xs")]: {
                        fontSize: "0.75rem",
                    },
                    WebkitFontSmoothing: "auto",
                    minHeight: "100vh",
                    padding: 0,
                    margin: 0,
                },
                body: {
                    fontFamily: "'Exo', sans-serif",
                    padding: 0,
                    margin: 0,
                    fontSize: 16,
                    fontWeight: 400,
                    // backgroundColor: "#FFFFFF",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    borderRadius: 50,
                    // background: "linear-gradient(90deg, #EE313A 0%, #FF9E2F 109.15%)",
                    background: `linear-gradient(90deg, ${primaryColors["900"]} 0%, ${primaryColors["300"]} 120%)`,
                    color: "#FFF",
                    minHeight: 56,
                    fontWeight: 400,
                },
                outlinedPrimary: {
                    "&, &:hover": {
                        borderRadius: 50,
                        minHeight: 56,
                        fontWeight: 400,
                        padding: "0.5rem 1rem",
                        position: "relative",
                        backgroundColor: "white",
                        backgroundClip: "padding-box",
                        border: "2px solid transparent",
                        color: primaryColors['700']
                    },
                    "&:hover": {
                        backgroundColor: alpha(primaryColors["900"], 0.25),
                        color: "#FFF",
                    },
                    "&:before": {
                        content: '""',
                        position: "absolute",
                        top: -2,
                        right: -2,
                        bottom: -2,
                        left: -2,
                        zIndex: -1,
                        backgroundImage: `linear-gradient(90deg, ${primaryColors["300"]} 50%, ${primaryColors["900"]} 120%)`,
                        borderRadius: 50,
                    },
                },
                containedSecondary: {
                    borderRadius: 50,
                    // background: "linear-gradient(90deg, #EE313A 0%, #FF9E2F 109.15%)",
                    background: `linear-gradient(90deg, ${secondaryColors["900"]} 0%, ${secondaryColors["300"]} 120%)`,
                    color: "#FFF",
                    minHeight: 56,
                    fontWeight: 400,
                },
                outlinedSecondary: {
                    "&, &:hover": {
                        borderRadius: 50,
                        minHeight: 56,
                        fontWeight: 400,
                        padding: "0.5rem 1rem",
                        position: "relative",
                        backgroundColor: "white",
                        backgroundClip: "padding-box",
                        border: "2px solid transparent",
                        color: secondaryColors['700']
                    },
                    "&:hover": {
                        backgroundColor: alpha(secondaryColors["900"], 0.25),
                        color: "#FFF",
                    },
                    "&:before": {
                        content: '""',
                        position: "absolute",
                        top: -2,
                        right: -2,
                        bottom: -2,
                        left: -2,
                        zIndex: -1,
                        backgroundImage: `linear-gradient(90deg, ${secondaryColors["300"]} 50%, ${secondaryColors["900"]} 120%)`,
                        borderRadius: 50,
                    },
                },
            },
        },
    },
};

// console.log({ theme })

export default theme;
